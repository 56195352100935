module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Classic AH WoW","short_name":"Classic AH","start_url":"/","background_color":"#444","theme_color":"#aaa","display":"standalone","icon":"static/manifest.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"344a88a6ea51da708791766d629e0769"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
